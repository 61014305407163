import { Link, useNavigate, useRouteLoaderData } from "@remix-run/react";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ButtonCompare from "../ButtonCompare";

import IconChevronRight from "~/components/atoms/Icons/IconChevronRight";
import ImageResponsive from "~/components/atoms/ImageResponsive";
import Typo from "~/components/atoms/Typo";
import UnitType from "~/components/atoms/UnitType";
import CardDetails from "~/components/molecules/CardDetails";
import { IProject } from "~/entities/project";
import { TUnitType } from "~/entities/unitType";
import useMediaQuery from "~/hooks/use-mediaquery";
import useAppState from "~/stores";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import { cn } from "~/utilities/cn";
import { TENURE_ITEM } from "~/utilities/constants/tenureItems";
import { EBgColor, ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";
import findFirstJpegSource from "~/utilities/helpers/findFirstImage";
import processPrices from "~/utilities/helpers/processPrices";
import { convertUnitTypeOptions } from "~/utilities/helpers/unitType";

export interface CardFeatureProps {
  full?: boolean;
  className?: string;
  thumbnailClass?: string;
  project: IProject;
  draggable?: boolean;
  unitTypeContainerClass?: string;
  isShownCompareButton?: boolean;
  showUnitsState?: boolean;
  handleOnSelectProject?: () => void;
}

const CardFeature: React.FC<CardFeatureProps> = ({
  full = false,
  className = "",
  thumbnailClass,
  project,
  draggable,
  unitTypeContainerClass = "",
  isShownCompareButton = true,
  showUnitsState = true,
  handleOnSelectProject,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [link, setLink] = useState<string>("");
  const thumbnail = findFirstJpegSource(project?.photo ? [project?.photo] : []);
  const isLargeScreen = useMediaQuery("lg");
  const [availableUnitTypes, setAvailableUnitTypes] = useState<TUnitType[]>([]);
  const { filterOptions } = useRouteLoaderData("root") as IRouteLoaderData;
  const convertedUnitOptions = convertUnitTypeOptions(filterOptions?.unitTypes);
  const defaultUnitOptions = convertedUnitOptions?.map((init) => ({
    id: init?.id,
    title: init?.name,
    totalUnits: 0,
    availableUnits: 0,
    isStudio: true,
  })) as TUnitType[];

  const cardClass = cn(
    "card justify-between bg-white rounded-3xl lg:rounded-lg hover:shadow-card-featured-hover lg:hover:shadow-none transition-shadow duration-200 h-fit",
    className
  );

  const figureClass = cn(
    "w-full h-full bg-backgroundImage overflow-hidden rounded-t-3xl lg:rounded-t-lg",
    full && "max-h-full",
    thumbnailClass
  );

  useEffect(() => {
    if (project?.slug) {
      setLink(`/projects/${project.slug}`);
    }
  }, [project.slug]);
  const { comparedProjects, addComparedProject } = useAppState(
    (state) => state
  );

  useEffect(() => {
    const unitTypes = project?.unitTypes?.filter((unitType) => {
      const unitPrice = processPrices(
        unitType?.totalUnits,
        unitType?.availableUnits,
        unitType?.minPrice,
        unitType?.maxPrice
      );

      return unitPrice !== "sold_out";
    });

    if (JSON.stringify(unitTypes) !== JSON.stringify(availableUnitTypes)) {
      setAvailableUnitTypes(unitTypes || []);
    }
  }, [project.unitTypes]);

  if (!project) return null;

  const isAdded =
    Array.isArray(comparedProjects) &&
    comparedProjects?.find(
      (comparedProject) => comparedProject?.id === project?.id
    );

  const limitUnitTypes = isLargeScreen ? 6 : 3;
  return (
    <Link
      draggable={draggable}
      to={link}
      className={cardClass}
      onClick={(e) => {
        e.preventDefault();
        if (typeof handleOnSelectProject === "function") {
          handleOnSelectProject();
        }
        navigate(link);
      }}
    >
      {isShownCompareButton && (
        <ButtonCompare
          addComparedProject={() => addComparedProject(project)}
          isAdded={!!isAdded}
          classNameButton="absolute z-10 right-5 top-5 lg:right-3 lg:top-3"
        />
      )}
      <figure className={figureClass}>
        <ImageResponsive
          className="h-full w-full object-cover"
          imageData={thumbnail}
          disclaimerClass="top-2 opacity-90 bottom-auto"
        />
      </figure>
      <div className="card-body relative -mt-6 gap-2 rounded-3xl bg-cardFeatureBackground p-5 lg:rounded-lg lg:p-3">
        <div className="flex flex-nowrap items-center justify-between gap-1">
          <Typo
            tag={ETypoTag.H3}
            variant={ETypoVariant.SUB_TITLE_18}
            color={ETypoColor.CARD_FEATURE}
            className="truncate whitespace-nowrap font-bold uppercase"
          >
            {project.name}
          </Typo>
          <Typo
            tag={ETypoTag.SPAN}
            variant={ETypoVariant.SUB_TITLE_16}
            color={ETypoColor.CARD_PROJECT_TENURE}
            className="whitespace-nowrap"
          >
            {TENURE_ITEM[project.tenure]?.name || ""}
          </Typo>
        </div>
        <CardDetails project={project} showUnitsState={showUnitsState} />
        <div
          className={cn("divider m-0 h-[1px]", EBgColor.CARD_FEATURE_DIVIDER)}
        ></div>
        <div
          className={cn(
            "flex flex-col gap-4.5 lg:gap-3",
            unitTypeContainerClass
          )}
        >
          {(isEmpty(availableUnitTypes)
            ? defaultUnitOptions
            : availableUnitTypes
          )
            ?.slice(0, limitUnitTypes)
            ?.filter(
              (unitType) =>
                !(
                  (unitType?.totalUnits > 0 &&
                    unitType?.availableUnits === 0) ||
                  (unitType?.minPrice === 0 && unitType?.maxPrice === 0)
                )
            )
            ?.map((unitType, index) => (
              <UnitType
                key={unitType.id + index}
                unitType={unitType}
                displaySqft={!isEmpty(availableUnitTypes)}
              />
            ))}

          {(isEmpty(availableUnitTypes)
            ? defaultUnitOptions
            : availableUnitTypes
          )?.length > limitUnitTypes && (
            <div className="group flex items-center justify-center gap-2 whitespace-nowrap">
              <Typo
                tag={ETypoTag.SPAN}
                variant={ETypoVariant.BODY_TITLE_16}
                color={ETypoColor.CARD_FEATURE}
                className="font-bold"
              >
                {t("view_more")}
              </Typo>
              <IconChevronRight className="-mb-[3px] text-cardFeatureText transition-all duration-500 group-hover:translate-x-1" />
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default CardFeature;
